/**
 *    ***********************************************************************
 *		FINES AND PENALTIES v2
 *		MAIN FILE
 *
 *		Turbolinks kinda messes with how JavaScript normally works in our
 *		applications so we need to do some extra things:
 *
 *		1. Load all the classes for each page at script start.
 *		2. When turbolinks requests a page, look for the class that handles that page and load it
 *		3. When turbolinks navigates away from a page, look for the class that handles that page and unload it
 *		4. When turbolinks wants to cache a page, look for the class that handles that page and reset it
 *
 *    ***********************************************************************
 */

import ViewFine from "./Fine/ViewFine";
import NewFine from "./Fine/NewFine";

window.__modules = [
	NewFine,
	ViewFine
];

let initialisedModules = [];
let previousPage = null;

/**
 * 1. Load all the classes for each page at script start.
 */
window.fp_start = () => {
	console.debug("[fp_start()] Called.");
	console.debug(`[fp_start()] Creating instances of ${window.__modules.length} modules.`);
	window.__modules.forEach(module => {
		initialisedModules.push(new module())
		console.debug(`[fp_start()] Created instance of module: ${module.constructor.name}.`);
	});

	console.debug("[fp_start()] All modules instances created.");
	
	console.debug("[fp_start()] Trying to load modules for the current page.");
	
	initialisedModules.forEach(module => {
		module.tryLoad(window.location.pathname)
	});
	previousPage = window.location.pathname;

	console.debug("[fp_start()] All modules have tried to load, one should have been successful.");
	document.getElementById("search").addEventListener("keyup", handleSearch);
	
	document.addEventListener("turbolinks:render", turbolinksRender);
	document.addEventListener("turbolinks:before-render", turbolinksBeforeRender);
	document.addEventListener("turbolinks:before-cache", turbolinksBeforeCache);
	
	

	/**
	 * 2. When turbolinks requests a page, look for the class that handles that page and load it
	 */
	function turbolinksRender() {
		console.debug("[turbolinks:render] Event handled. Current pathname: " + window.location.pathname);
		initialisedModules.forEach(module => {
			module.tryLoad(window.location.pathname)
		});
		previousPage = window.location.pathname;
		document.getElementById("search").addEventListener("keyup", handleSearch);
	}

	/**
	 * 3. When turbolinks navigates away from a page, look for the class that handles that page and unload it
	 */
	function turbolinksBeforeRender() {
		document.getElementById("search").removeEventListener("keyup", handleSearch);
		console.debug("[turbolinks:before-render] Event handled. Previous Page: " + previousPage);
		if (previousPage !== null) initialisedModules.forEach(module => module.tryUnload(previousPage));
	}

	/**
	 * 4. When turbolinks wants to cache a page, look for the class that handles that page and reset it
	 */
	function turbolinksBeforeCache() {
		console.debug("[turbolinks:before-cache] Event handled. Previous Page: " + previousPage);
		initialisedModules.forEach(module => module.tryReset(previousPage));
	}

	function handleSearch(event) {
		let currentSearchValue = document.getElementById("search").value;

		// If key is enter, search immediately
		if (event.key === "Enter") window.m_Search(currentSearchValue, true);
		// Otherwise, check in 2 seconds if the value has changed. If it hasn't then search.
		else setTimeout(() => {
			if (document.getElementById("search").value === currentSearchValue && !window.isCurrentlySearching) window.m_Search(currentSearchValue, false);
		}, 2000);
	}
}